import React from 'react';
import { connect } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import NoveltyCard from '../novelty-card';
import ContentLoader from "react-content-loader"

import '../../../node_modules/animate.css/animate.css';

import {updateFiltersNovelties,getFiltersNoveltiesAction,clearNoveltyAction} from "../../redux/noveltiesDucks";

import { useEffect, useState } from 'react';

const Main = ({loading_grid,novelties,grid_novelties,tags,dispatch}) => {
    //loading_novelties, loading_tags,(lo saqué de las props)
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
          }
    }`)

    const [listActives,setListActive] = useState([])

    const toggleTag = (id) => {
        if(listActives.find(e => e === id)){
            return setListActive(listActives.filter(e => e !== id))
        }
        return setListActive([...listActives,id]);
    } 

    useEffect(() => {
        dispatch(updateFiltersNovelties(listActives));
    }, [listActives])

    useEffect(() => {
        dispatch(clearNoveltyAction());
    } ,[])


    return(
        <section className="novelty-main">
            <div className="container-fluid">
                <div class="row py-lg-5 pt-3 pb-3 align-items-start">
                    <div class="col-lg-4 mb-4 first_div">
                        <h2 class="page-title pb-4">Novedades</h2> 
                    </div>
                    <div class="col-lg-8 justify-content-lg-end">    
                        <div className="second_div">
                            <div className="div_search d-flex justify-content-between">
                                <i className="d-none icon-price-tag me-3 ms-2 d-md-flex"></i>
                                <div className="pills_search d-flex justify-content-between">
                                    <div className="div_pills flex-wrap">
                                        {listActives.map((element, index) =>(
                                            <span className="animate__animated animate__fadeInRight pill btn me-2 mt-2 mt-lg-0">{tags.find(item => item.id === element).name}<i onClick={() => toggleTag(element)} className="icon-close-light"></i></span>
                                        ))}
                                    </div>
                                    <div className="div_buttons d-flex">
                                        <button className="btn-clear me-3 d-none d-md-block cursor-pointer" onClick={() => setListActive([])}>Limpiar</button>
                                        <button className="btn btn-share-icon d-lg-none cursor-pointer" onClick={() => dispatch(getFiltersNoveltiesAction(listActives))}><i className="icon-search"></i></button>
                                        <button className="btn btn-share d-none d-lg-flex cursor-pointer" onClick={() => dispatch(getFiltersNoveltiesAction(listActives))}>BUSCAR <i className="icon-search ms-3"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="div_etiquetas flex-wrap">
                                {console.log(tags)}
                                {
                                // loading_tags ? 
                                //     [1,2,3].map(count => (
                                //         <div className="ms-3 mb-2 d-inline-block">
                                //             <span className='animate__animated animate__fadeInDown cursor-pointer pill btn skeleton'>Loading</span>
                                //         </div>
                                //     ))
                                // : 
                                    tags?.map((tag,index) => (
                                        listActives.find(e => e === tag.id) ? '' :
                                        <span key={index} onClick={() => toggleTag(tag.id)} className='animate__animated animate__fadeInDown cursor-pointer pill btn'>{tag.name}</span>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="div_novedades row d-grid">
                {
                    loading_grid ?
                        <>
                            {[1,2,3,4,5].map(count => (
                                <div className="px-3 mb-4">
                                    <NoveltyCard skeleton={true} />
                                </div>
                            ))}
                        </>
                    :
                        grid_novelties.length > 0 ?
                            grid_novelties.map((novelty,index) => (
                                <div className="px-3 mb-4">
                                    <NoveltyCard novelty={novelty} />
                                </div>
                            ))
                        :
                        <div className="col-12">
                            <h5 className="text-center mb-5">No hay resultados para esta busqueda</h5>
                        </div>
                }
                </div>
            </div>
        </section>
    )
} 

export default connect(state => ({
    // loading_novelties: state.novelties.loading_novelties,
    loading_grid: state.novelties.loading_grid,
    // loading_tags: state.novelties.loading_tags,
    novelties: state.novelties.novelties,
    grid_novelties: state.novelties.grid_novelties,
    tags: state.novelties.tags
  }),null)(Main);